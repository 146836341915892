import { ResponsiveContainer } from "recharts";
import "./App.css";
import AttackL3Chart from "./charts/attackL3Charts";
import DomainRankings from "./charts/domainRankings";
import TrafficChart from "./charts/trafficCharts";

function App() {
  return (
    <>
      <div id="internetTraffic">
        <p>Internet traffic over the past 30 days:</p>
        <ResponsiveContainer>
          <TrafficChart />
        </ResponsiveContainer>
      </div>
      <div id="layer3Attacks">
        <p>Layer 3 DDoS attacks over the last 30 days:</p>
        <ResponsiveContainer>
          <AttackL3Chart />
        </ResponsiveContainer>
      </div>
      <div id="domainRankings">
        <p>Top 15 domains:</p>
        <DomainRankings />
      </div>
    </>
  );
}

export default App;
