import { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default function AttackL3Chart() {
  let [data, setData] = useState([]);
  useEffect(() => {
    fetch("https://router.icfelix.workers.dev/attack-layer3")
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      })
      .then((json) => {
        // Clean JSON data.
        let vals = [];
        let dataValues = json.data;
        Object.keys(dataValues).forEach((key) => {
          vals.push({
            name: key,
            attacks: dataValues[key],
          });
        });
        setData(vals);
      });
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let payloadDate = new Date(label).toLocaleString();
      return (
        <div className="tooltip">
          <p className="label">{`${payloadDate} : ${payload[0].value}`}</p>
          <p className="intro">
            Percentage change in layer 3 DDoS attack volume on {payloadDate}.
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <LineChart
      width={800}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" interval="preserveStartEnd" minTickGap={8} />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Line type="monotone" dataKey="attacks" stroke="#8884d8" dot={false} />
    </LineChart>
  );
}
